import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import Button from "react-bootstrap/Button";
import PhotoLeft from "../../components/photo-left";
import TreatmentHero from "../../components/treatment-hero";
import TreatmentCard from "../../components/treatment-card";
import ReviewSection from "../../components/review-section";
import TreatmentImageSection from "../../components/treatment-image-section";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Faq from "../../components/faq";
import { checkPropertiesForNull } from "../../../utils";
import { SafeHtmlParser } from "../../components/safeHtmlParser";
import uuid4 from "uuid4";

const TeethWhiteningPage = () => {
  const data = useStaticQuery(graphql`
    query {
      reviews {
        reviewCount
      }
      schemaLogo: wpMediaItem(title: { eq: "dark-logo" }) {
        altText
        localFile {
          publicURL
          childImageSharp {
            original {
              height
              width
            }
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: TRACED_SVG
            )
          }
        }
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
      pageData: wpPage(slug: { eq: "teeth-whitening-exeter" }) {
        slug
        seoFieldGroups {
          metaTitle
          metaDescription
          localBusinessSchema
          openGraphDescription
          openGraphTitle
          productSchema
          image {
            node {
              altText
              publicUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                  original {
                    width
                    height
                  }
                }
              }
            }
          }
        }
        teethWhiteningExeterFieldGroups {
          teethWhiteningIntroSection {
            heading
            description
            title
            subtitle
            cta {
              title
              target
              url
            }
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
          teethWhiteningReviewSection {
            heading
            subHeading
          }
          teethWhiteningTreatmentSection {
            heading
            subHeading
            description
            cta {
              title
              target
              url
            }
            items {
              heading
              content
              price
              image {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP]
                        quality: 100
                        transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                        layout: CONSTRAINED
                        placeholder: BLURRED
                      )
                    }
                  }
                }
              }
            }
          }
          teethWhiteningAdvantageSection {
            heading
            content
          }
          teethWhiteningTwoColumnSection {
            heading
            content
            cta {
              title
              target
              url
            }
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
          teethWhiteningFaqSection {
            heading
            faqs {
              nodes {
                ... on WpFaq {
                  faqFields {
                    question
                    answer
                  }
                }
              }
            }
          }
          teethWhiteningGallerySection {
            heading
            subHeading
            images {
              nodes {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const {
    pageData: { seoFieldGroups, teethWhiteningExeterFieldGroups },
  } = data;

  const {
    teethWhiteningIntroSection,
    teethWhiteningReviewSection,
    teethWhiteningTreatmentSection,
    teethWhiteningAdvantageSection,
    teethWhiteningTwoColumnSection,
    teethWhiteningFaqSection,
    teethWhiteningGallerySection,
  } = teethWhiteningExeterFieldGroups;

  const siteUrl = data.site.siteMetadata.siteUrl;

  const serviceSchema = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "@id":
      "https://www.dentalhouseexeter.co.uk/treatments/teeth-whitening/#teeth-whitening",
    image: `https://www.dentalhouseexeter.co.uk${seoFieldGroups?.image?.node.localFile.publicURL}`,
    mpn: "https://www.dentalhouseexeter.co.uk/treatments/teeth-whitening",
    sku: "https://www.dentalhouseexeter.co.uk/treatments/teeth-whitening",
    description: `${seoFieldGroups?.metaDescription}`,
    logo: `https://www.dentalhouseexeter.co.uk${data.schemaLogo.localFile.publicURL}`,
    name: "Dental House Exeter",
    url: "https://www.dentalhouseexeter.co.uk",
    brand: {
      "@type": "Organization",
      logo: `https://www.dentalhouseexeter.co.uk${data.schemaLogo.localFile.publicURL}`,
      image: `https://www.dentalhouseexeter.co.uk${seoFieldGroups?.image?.node.localFile.publicURL}`,
      name: "Dental House Exeter",
      url: "https://www.dentalhouseexeter.co.uk",
    },
    aggregateRating: {
      "@type": "AggregateRating",
      reviewCount: `${data.reviews.reviewCount}`,
      ratingValue: "5",
      bestRating: "5",
      worstRating: "1",
    },
    review: {
      "@type": "Review",
      url: "https://www.dentalhouseexeter.co.uk/reviews",
      datePublished: "2022-03-21T17:58:29+00:00",
      reviewBody:
        "This is the best dental practice I have known. Not only in terms of expertise but the lovely sociability of every single member of the practice team.",
      author: {
        "@type": "Person",
        name: "Caroline P",
      },
      reviewRating: {
        "@type": "Rating",
        ratingValue: "5",
        bestRating: "5",
        worstRating: "1",
      },
      publisher: {
        "@type": "Organization",
        name: "Dental House Exeter",
        sameAs: "https://www.dentalhouseexeter.co.uk",
      },
    },
  };

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Treatments",
        item: {
          url: `${siteUrl}/treatments`,
          id: `${siteUrl}/treatments`,
        },
      },
      {
        "@type": "ListItem",
        position: "3",
        name: "Teeth Whitening",
        item: {
          url: `${siteUrl}/treatments/teeth-whitening`,
          id: `${siteUrl}/treatments/teeth-whitening`,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(serviceSchema)}
        </script>
      </Helmet>
      {/* <LocalBusinessSchema /> */}
      <GatsbySeo
        title={seoFieldGroups?.metaTitle}
        description={seoFieldGroups?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/treatments/teeth-whitening`,
          title: `${seoFieldGroups?.openGraphTitle}`,
          description: `${seoFieldGroups?.openGraphDescription}`,
          images: [
            {
              url: `${seoFieldGroups?.image?.node.localFile.publicURL}`,
              width: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.width}`,
              height: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.height}`,
              alt: `${seoFieldGroups?.image?.node.altText}`,
            },
          ],
        }}
      />

      <Layout>
        {teethWhiteningIntroSection &&
          !checkPropertiesForNull(teethWhiteningIntroSection, ["heading"]) && (
            <TreatmentHero
              img={
                teethWhiteningIntroSection?.image.node?.localFile
                  .childImageSharp.gatsbyImageData
              }
              imgAlt={teethWhiteningIntroSection?.image.node?.altText}
              heading1={teethWhiteningIntroSection?.title}
              smallHeadingTopText={teethWhiteningIntroSection?.subTitle}
              heading2={teethWhiteningIntroSection?.heading}
              text={
                <SafeHtmlParser
                  htmlContent={teethWhiteningIntroSection?.description}
                />
              }
              ctaButton={teethWhiteningIntroSection?.cta}
            />
          )}
        <ReviewSection
          heading={
            teethWhiteningReviewSection?.heading ||
            "Reviews From Our Dentistry Patients"
          }
          smallheading={
            teethWhiteningReviewSection?.subHeading ||
            "WHAT PEOPLE SAY ABOUT US"
          }
          page="Teeth whitening"
        />

        <section className="pt-8 pb-md-6">
          <Container>
            <>
              <Row>
                <Col className="text-center">
                  <p className="text-accent jost-bold fs-6">
                    {teethWhiteningTreatmentSection?.subHeading}
                  </p>
                  <h2 className="text-text-secondary display-4 pb-4">
                    {teethWhiteningTreatmentSection?.heading}
                  </h2>
                  <SafeHtmlParser
                    htmlContent={teethWhiteningTreatmentSection?.description}
                  />
                </Col>
              </Row>
              {teethWhiteningTreatmentSection.items &&
                teethWhiteningTreatmentSection.items.length > 0 && (
                  <Row className="pt-md-8 pt-5 h-100 justify-content-center">
                    {" "}
                    {teethWhiteningTreatmentSection.items.map((cardItem) => (
                      <TreatmentCard
                        key={uuid4()}
                        image={
                          cardItem?.image.node?.localFile.childImageSharp
                            .gatsbyImageData
                        }
                        imgAlt={cardItem?.image.node?.altText}
                        title={cardItem?.heading}
                        text={
                          <SafeHtmlParser htmlContent={cardItem?.content} />
                        }
                        span={4}
                        price={cardItem?.price}
                        shorter="image-shorter"
                      />
                    ))}
                  </Row>
                )}
              {teethWhiteningTreatmentSection.cta &&
                teethWhiteningTreatmentSection.cta.url && (
                  <Row>
                    <Col className="text-center mt-md-6">
                      {" "}
                      <Button
                        className="text-white py-3 px-5 w-100 w-md-auto"
                        variant="accent"
                        href={teethWhiteningTreatmentSection.cta?.url}
                        target={
                          teethWhiteningTreatmentSection.cta?.target ?? "_blank"
                        }
                        rel="noreferrer"
                      >
                        {teethWhiteningTreatmentSection.cta?.title}
                      </Button>
                    </Col>
                  </Row>
                )}
            </>
            {teethWhiteningAdvantageSection &&
              !checkPropertiesForNull(teethWhiteningAdvantageSection, [
                "heading",
              ]) && (
                <Row className="mt-5">
                  <Col>
                    <h2 className=" text-center fs-1 pb-4">
                      {teethWhiteningAdvantageSection?.heading}
                    </h2>
                    <SafeHtmlParser htmlContent={teethWhiteningAdvantageSection?.content}/>
                  </Col>
                </Row>
              )}
          </Container>
        </section>

        {teethWhiteningTwoColumnSection &&
          !checkPropertiesForNull(teethWhiteningTwoColumnSection, [
            "heading",
          ]) && (
            <section className="pt-8">
              {" "}
              <PhotoLeft
                img={
                  teethWhiteningTwoColumnSection?.image.node?.localFile
                    .childImageSharp.gatsbyImageData
                }
                imgAlt={teethWhiteningTwoColumnSection?.image.node?.altText}
                text={
                  <div>
                    <h2 className=" text-center text-lg-start display-4 pb-4">
                      {teethWhiteningTwoColumnSection?.heading}
                    </h2>
                    <GatsbyImage
                      className="mb-4 w-100 d-lg-none"
                      image={
                        teethWhiteningTwoColumnSection?.image.node?.localFile
                          .childImageSharp.gatsbyImageData
                      }
                      alt={teethWhiteningTwoColumnSection?.image.node?.altText}
                    />
                    <SafeHtmlParser
                      htmlContent={teethWhiteningTwoColumnSection?.content}
                    />
                    {teethWhiteningTwoColumnSection &&
                      teethWhiteningTwoColumnSection.cta && (
                        <Button
                          className="text-white py-3 px-5 w-100 w-md-auto"
                          variant="accent"
                          href={teethWhiteningTwoColumnSection?.cta.url}
                          target={
                            teethWhiteningTwoColumnSection.cta.target ??
                            "_blank"
                          }
                          rel="noreferrer"
                        >
                          {teethWhiteningTwoColumnSection?.cta.title}
                        </Button>
                      )}
                  </div>
                }
                bgColour="bg-bg"
              />
            </section>
          )}
        {teethWhiteningFaqSection &&
          !checkPropertiesForNull(teethWhiteningFaqSection, ["faqs"]) && (
            <Faq
              heading={teethWhiteningFaqSection?.heading}
              faqs={teethWhiteningFaqSection?.faqs?.nodes}
            />
          )}
        {teethWhiteningGallerySection &&
          !checkPropertiesForNull(teethWhiteningGallerySection, [
            "heading",
          ]) && (
            <TreatmentImageSection
              images={teethWhiteningGallerySection?.images.nodes}
              heading={teethWhiteningGallerySection?.heading}
              headingSmall={teethWhiteningGallerySection?.subHeading}
            />
          )}
      </Layout>
    </>
  );
};

export default TeethWhiteningPage;
